import { Fragment, useEffect, lazy } from "react";
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { STALE_SERVE } from "../dermuscomponents/src/Constants/Message";
import { setStaleActionCreator } from "../dermuscomponents/src/store/actionCreators/patientManagementActionCreator";
import * as links from "../dermuscomponents/src/Constants/Links";
import { addSnackbar } from "../dermuscomponents/src/UI/Snackbar/Snackbar";
import { Box } from "@mui/material";
import { buildSearchQuery, generateToken } from "../dermuscomponents/src/utility/utility";
import { isDemo } from "../dermuscomponents/src/utility/functions";

const Login = lazy(() => import("../dermuscomponents/src/Components/Authentication/Login"))
const Logout = lazy(() => import("../dermuscomponents/src/Components/Authentication/Logout"))
const UserPreferences = lazy(() => import("../dermuscomponents/src/Components/Authentication/UserPreferences"))
const Calibration = lazy(() => import("./SS-U/Calibration"))
const USBSettings = lazy(() => import("../dermuscomponents/src/Communication/USBSettings"))
const PrimarySpinner = lazy(() => import("../dermuscomponents/src/UI/Spinner/PrimarySpinner"))
const NewFeatureBox = lazy(() => import("../dermuscomponents/src/UI/Other/NewFeatureBox"))
const CaptureWrapper = lazy(() => import("./SS-U/CaptureWrapper"))
const About = lazy(() => import("../dermuscomponents/src/Components/About"));
const Feedback = lazy(() => import("../dermuscomponents/src/Components/Other/Feedback"));


const Routing = () => {
    //Redux dispatch
    const dispatch = useDispatch();
    //Stale cache
    const stale = useSelector((state) => state.patMan.stale);
    //Cognito session
    const session = useSelector((state) => state.auth.session);
    //Location hook
    const location = useLocation();
    //History
    const history = useHistory()

    useEffect(() => {
        if (stale) {
            addSnackbar({
                message: STALE_SERVE,
                onClose: () => dispatch(setStaleActionCreator(false)),
                preventDuplicate: true
            })
        }// eslint-disable-next-line
    }, [stale])


    //Redirect to skinaid
    useEffect(() => {
        if ([links.SETTINGS_URL].includes(location.pathname)) {
            window.location.href = `${links.SKINAID_FULL_URL}${location.pathname}?${buildSearchQuery({ userToken: generateToken({ user: session.getIdToken().payload.sub }) })}`
        }//eslint-disable-next-line
    }, [location])

    useEffect(() => {
        if (isDemo()) {
            if ([links.USB_SETTINGS_URL, links.SETTINGS_URL, links.CALIBRATION_URL, links.USER_PREFERENCES_URL].includes(location.pathname)) {
                history.replace("")
            }
        }// eslint-disable-next-line
    }, [location.pathname])

    //Loged in
    if ((session === null || location.pathname.endsWith(links.VERIFY_EMAIL_URL)) && !isDemo()) {
        return (
            <Fragment>
                <Switch>
                    <Route path={links.LOGOUT_URL} exact component={Logout} />
                    <Route path={links.LOGIN_URL} component={Login} />
                    <Redirect
                        to={links.LOGIN_URL + location.pathname +
                            location.search +
                            location.hash}
                    />
                </Switch>
            </Fragment>
        );
    } else if (session === undefined || (isDemo() && (session && !session.restored))) {//Not read yet
        return <Box sx={{ display: "flex", justifyContent: "center" }}>
            <PrimarySpinner />
        </Box>
    } else {
        return (
            <Fragment>
                {!isDemo() && <NewFeatureBox />}
                <Switch>
                    <Route path={links.ABOUT_URL} exact component={About} />
                    <Route path={links.LOGOUT_URL} exact component={Logout} />
                    <Route path={links.USB_SETTINGS_URL} exact component={USBSettings} />
                    <Route path={links.CALIBRATION_URL} exact component={Calibration} />
                    <Route path={links.CAPTURE_URL} exact component={CaptureWrapper} />
                    <Route path={links.USER_PREFERENCES_URL} exact component={UserPreferences} />
                    <Route path={links.FEEDBACK_URL} exact component={Feedback} />


                    <Redirect
                        from={links.LOGIN_URL}
                        to={
                            location.pathname.substring(links.LOGIN_URL.length) +
                            location.search +
                            location.hash
                        }
                    />
                    <Redirect to={links.CAPTURE_URL +
                        location.search +
                        location.hash} />
                </Switch>
            </Fragment>
        );
    }


};

export default Routing;